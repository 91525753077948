import React from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { Link } from 'gatsby-theme-material-ui';
import ProTip from '../components/ProTip';
import Copyright from '../components/Copyright';
import Header from '../components/header';
import Content from '../components/Content';
import PageIntro from '../components/pageIntro';
import PageTitle from '../components/pageTitle';
import Card4 from '../components/Card4';




export default function About() {
  return (
    <Grid Container direction="column">
      <Grid item>
        <Header title="Implication"/>
      </Grid>
      <Grid item>
      <Container maxWidth="sm">
      <Box my={4}>
      <PageTitle/>
      <PageIntro content="Ma conviction indubitable que l'implication de tous est la clé du succès structure mon approche de chaque projet, seule solution selon moi aux enjeux de complexité inhérents à tout projet transformant le quotidien. " /> 
      <Card4 title="Recap projet hebdomadaire" customer="" description="Pour impliquer les porteurs de projet, ce récapitulatif hebdomadaire  concatène en 4 indicateurs simple de temps et de budget les avancées de la semaine chaque minute de temps passé par les uns et les autres sur le projet" moreLink="" />
<Card4 title="Solution myHub" customer="" description="Pour impliquer à tous les niveaux, la solution myHub est une méthode à laquelle nous avons abouti pour mettre l'accent avant tout sur l'impact, dès le premier mois, d'un projet quel qu'il soit, et prendre en compte d'entrée le caractère complexe et changeant de l'environnement de développement" moreLink="" />
<Card4 title="Point du matin" customer="" description="Pour impliquer les collègues, ce grand classique du Lean est la base du travail en équipe.  Ce moment quotidien est particlièrement précieux car il est l'occasion de faire comprendre à chacun les enjeux des autres" moreLink="" />
<Card4 title="Recap projet de fin d'étape" customer="" description="Pour impliquer les décideurs, ce récapitulatif de fin d'étape, mensuel, explique en quelques phrases la tendance sur le projet : c'est la clé pour réagir à temps et dans les bonnes proportions" moreLink="" />
<Card4 title="Association par points annuels" customer="" description="Pour impliquer les collègues et devenir la meilleure équipe possible, les responsabilités doivent être partagées. Chez Southside Interactive, cela signifiait partager également le capital, ce que nous avons essayé de faire avec cet actionnariat par points annuels." moreLink="" />
<Card4 title="Matrice avance / retard" customer="" description="Pour impliquer les porteurs de projet, cet outil de réévaluation permanente du planning et du budget permet de compiler chantier par chantier les efforts produits, les succès remportés et les difficultés à intégrer" moreLink="" />
<Card4 title="Planning vivant" customer="" description="Pour impliquer les décideurs, cet outil de réévaluation permanente permet à la fois de suivre l'évolution du projet, et de suivre les estimations précédentes, pour gagner en expérience" moreLink="" />
<Card4 title="Pyramide d'implication 'Train the trainer'" customer="" description="Pour impliquer les futurs utiisateurs, cette approche de déploiement 'Train the trainer' démulitplie les occasions de former, et met la transmission au cœur du projet informatique" moreLink="" />
<Card4 title="Enquête utilisations " customer="" description="Pour impliquer les porteurs de projet et leur permettre de se rendre compte concrètement comment sera utilisée l'outil qu'ils créent, et prendre du recul par rapport aux fonctionnalités elles mêmes" moreLink="" />
<Card4 title="Formulaire avis utilisateurs" customer="" description="Pour impliquer les utilisateurs, et acter que le succès d'un outil passe par leur bonne utilisation. Plus que la bonne idée qui peut émerger de leurs utilisations, c'est l'attitude de l'utilisateur participant à l'outil que ce formulaire permet" moreLink="" />
<Card4 title="Questionnaire besoin concret" customer="" description="Pour impliquer les porteurs de projet et leur permettre de se projeter au-delà du fonctionnel, appréhender tout ce qui se trouve autour des fonctionnalités elles mêmes, et qui seront les vraies clés du succès" moreLink="" />
<Card4 title="Notifications documentation projet" customer="" description="Pour impliquer les porteurs de projet, l'utilisation d'u outil de documentation mis à jour en permanence et notifiant toutes les parties prenantes à chaque modification, garantissant que tout est vu et cautionné" moreLink="" />
<Card4 title="Ateliers UX" customer="" description="Pour impliquer les porteurs de projet, les ateliers UX sont des moments privilégiés qui permettent de visualiser la variété des réactions à des propositions qui semblaient pourtant parfaitement logiques mais qui sont reçues avec toute les contradictions que peuvent receler chaque situation particulière" moreLink="" />
<Card4 title="Formations" customer="" description="Pour impliquer les porteurs de projet, j'ai structuré trois formations sur les grandes lignes des aspects techniques et méthodologiques d'un projet digital en entreprise" moreLink="" />
<Card4 title="Miro" customer="" description="Pour impliquer les porteurs de projet, Miro est un outil qui permet de travailler de concert, en temps réel, et de figurer concrètement les suggestions et idées qui deviennent beaucoup plus concrètes une fois représentées" moreLink="" />
<Card4 title="Confluence" customer="" description="Pour impliquer les porteurs de projet, la mise en place d'un espace documentaire complet sur Confluence est une étape indispensable, garant de la pérennité des développements dans le temps." moreLink="" />
<Card4 title="Formulapp" customer="" description="Pour impliquer les porteurs de projet, lancer une application qui n'est qu'un simple formulaire dès le premier mois est un très bon moyen pour eux de prendre conscience des obstacles à l'utilisation de l'outil qu'ils veulent créer bien avant les fonctionnalités elles-mêmes" moreLink="" />
<Card4 title="Newsletter" customer="" description="Pour impliquer toutes les parties prenantes, les newsletters d'information sont un moyen pratique de rester dans les esprits ; ce classique du e-commerce ou de la communication se révèle d'autant plus fructueux dans des contextes d'outils métiers qu'il touche au quotidien de travail des destinataires et les concerne plus directement." moreLink="" />
      </Box>
      </Container>
      </Grid>
    </Grid>
  );
}
